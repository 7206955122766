import { createSelector } from 'reselect';
import { DISCOUNT_TYPE_FLAT, DISCOUNT_TYPE_PERCENT } from '../reducers/reservedSeating';
import { toMinorUnit } from '@h/currency';
import { getMainEvent, getSelectedEvent } from './event';

const getBestAvailableQuantities = state => state.reservedSeating.bestAvailableQuantities;
const getSectionLevelQuantities = state => state.reservedSeating.sectionLevelQuantities;
const getShowBestAvailablePickerFromUi = state => !!state.ui.showBestAvailablePicker;
const getShowPyosPickerFromUi = state => !!state.ui.showPyosPicker;
const getUiState = state => state.ui;
const getPriceLevels = state => state.priceLevel.levels;
const getTickets = state => state.tickets.tickets;
const getReserveCoupons = state => Array.isArray(state.coupon.reserveCouponUsages) ? state.coupon.reserveCouponUsages : [];

/**
 * Is the main event associated with this listing seated
 */
export const isSeatedListing = createSelector([getMainEvent],(event) =>  {
    return eventIsSeated(event);
});

/**
 * Does the main event use seatsio
 */
export const listingUsesSeatsio = createSelector([getMainEvent],(event) =>  {
    return eventUsesSeatsio(event);
});

/**
 * Get the total number of selected seats for the best available seat picker
 */
export const numberSelectedBestAvailableSeats = createSelector([getBestAvailableQuantities, getSectionLevelQuantities],(bestAvailableQuantities, sectionQuantities) =>  {
    let count = 0;
    Object.values(bestAvailableQuantities).forEach(value => {
        count += +value;
    });
    Object.values(sectionQuantities).forEach(value => {
      count += +value;
    });
    return count;
});

/**
 * Get the name of the category that the user is requesting best available tickets for
 */
export const bestAvailableSelectedCategoryKey = createSelector([getBestAvailableQuantities, getPriceLevels],(bestAvailableQuantities, priceLevels) =>  {
    let result = null;
    const quantityKeys = Object.keys(bestAvailableQuantities);
    if (quantityKeys.length) {
        const selectedKey = quantityKeys[0];
        if (selectedKey != 'best_available') {
            Object.values(priceLevels).forEach(level => {
                if (+level?.price_level_id === +selectedKey && !!level.seating_chart_category_key) {
                    result = level.seating_chart_category_key;
                }
            });
        }
    }
    return result;
});

export const bestAvailableSelectedIsGA = createSelector([getBestAvailableQuantities, getPriceLevels],(bestAvailableQuantities, priceLevels) =>  {
  let result = null;
  const quantityKeys = Object.keys(bestAvailableQuantities);
  if (quantityKeys.length) {
      const selectedKey = quantityKeys[0];
      if (selectedKey != 'best_available') {
          Object.values(priceLevels).forEach(level => {
              if (+level?.price_level_id === +selectedKey) {
                  result = !level.is_seated;
              }
          });
      }
  }
  return result;
});


/**
 * Get the loaded section price levels for the main event or selected series child event
 */
export const getSectionPriceLevels = createSelector([getSelectedEvent],(event) =>  {
    return eventIsSeated(event) ? getSectionPriceLevelsFromSelectedEvent(event) : [];
});

/**
 * Check if any section price level minimums are not being met
 */
export const getSectionPriceLevelsWithUnmetMinimums = createSelector([getSelectedEvent, getTickets],(event, tickets) =>  {
    const sectionPriceLevels = getSectionPriceLevelsFromSelectedEvent(event);
    const quantitiesBySectionLevelId = {};
    tickets.forEach(ticket => {
        if (!!ticket?.seat_info?.section_level_id) {
            if (!quantitiesBySectionLevelId[ticket.seat_info.section_level_id]) {
                quantitiesBySectionLevelId[ticket.seat_info.section_level_id] = 0;
            }
            quantitiesBySectionLevelId[ticket.seat_info.section_level_id]++;
        }
    });
    return sectionPriceLevels.filter(lvl => {
        return !!lvl?.minimum_purchase_limit &&
               +lvl.minimum_purchase_limit > 0 &&
               !!quantitiesBySectionLevelId[lvl.id] &&
               +quantitiesBySectionLevelId[lvl.id] < +lvl.minimum_purchase_limit;
    });
});

const getSectionPriceLevelsFromSelectedEvent = event => {
    let levels = [];
    if (Array.isArray(event?.section_price_levels)) {
        levels = event.section_price_levels;
    }
    return levels;
}

/**
 * Get fee map for loaded section price levels
 */
export const getSectionFeeMap = createSelector([getSelectedEvent],(event) =>  {
    return typeof event?.section_fee_map === 'object' && event?.section_fee_map !== null && !Array.isArray(event?.section_fee_map) ? event.section_fee_map : {};
});

/**
 * Does the event have the HIDE_BEST_AVAILABLE setting
 */
export const hideBestAvailable = createSelector([getSelectedEvent],(event) =>  {
    return !!event?.reserved_seating?.hideBestAvailable;
});

/**
 * Does the event have the DISABLE_BEST_AVAILABLE setting
 */
export const disableBestAvailable = createSelector([getSelectedEvent],(event) =>  {
    return !!event?.reserved_seating?.disableBestAvailable;
});

/**
 * Should we show the best available seat picker instead of the regular ticket picker
 */
export const showBestAvailablePicker = createSelector([getSelectedEvent, getUiState, getReserveCoupons],(event, uiState, reserveCouponUsages) =>  {
    return eventIsSeated(event) && (reserveCodeApplied(event, reserveCouponUsages) || !eventIsPyos(event) || !!uiState.showBestAvailablePicker);
});

/**
 * Should we show the PYOS seat picker instead of the regular ticket picker
 */
export const showPyosPicker = createSelector([getSelectedEvent, getUiState, getReserveCoupons],(event, uiState, reserveCouponUsages) =>  {
    return eventIsSeated(event) && !reserveCodeApplied(event, reserveCouponUsages) && eventIsPyos(event) && !!uiState.showPyosPicker;
});

/**
 * USE_SERVER_SIDE_BEST_AVAILABLE setting
 */
export const useServerSideBestAvailable = createSelector([getSelectedEvent],(event) =>  {
    return eventUsesServerSideBestAvailable(event);
});

/**
 * Does the event support pyos
 */
export const isPyos = createSelector([getSelectedEvent],(event) =>  {
    return eventIsPyos(event);
});

/**
 * Calculate the purchase limit
 */
export const purchaseLimit = createSelector([getSelectedEvent],(event) =>  {
    let limit = 0;
    if (Array.isArray(event?.price_levels)) {
        event.price_levels.forEach(level => {
            if (+level?.transaction_limit > limit) {
                limit = +level.transaction_limit;
            }
        });
    }
    return limit;
});

/**
 * What should the best available picker increment by
 */
export const incrementBy = createSelector([getSelectedEvent],(event) =>  {
    return +event?.reserved_seating?.incrementBy > 0 ? +event.reserved_seating.incrementBy : 1;
});

/**
 * Is a custom default price level name set
 */
export const defaultPriceLevelName = createSelector([getSelectedEvent],(event) =>  {
    return event?.reserved_seating?.defaultPriceLevelName;
});

/**
 * Get the charting service public key
 */
export const rsPublicKey = createSelector([getSelectedEvent],(event) =>  {
    return event?.reserved_seating?.publicKey;
});

/**
 * Get the carting service event id
 */
export const rsEventId = createSelector([getSelectedEvent],(event) =>  {
    return event?.reserved_seating?.eventId;
});

/**
 * Should the prices on discount levels be hidden
 */
export const hidePremierePriceDiscount = createSelector([getSelectedEvent],(event) =>  {
    return !!event?.reserved_seating?.hidePremierePriceDiscount;
});

/**
 * ONLY_SHOW_BEST_AVAILABLE setting
 */
export const onlyShowBestAvailable = createSelector([getSelectedEvent],(event) =>  {
    return !!event?.reserved_seating?.onlyShowBestAvailable;
});

/**
 * ALLOW_ORPHAN_SEATS setting
 */
export const allowOrphanSeats = createSelector([getSelectedEvent],(event) =>  {
    return !!event?.reserved_seating?.allowOrphanSeats;
});

/**
 * ORPHAN_SEAT_MESSAGE setting
 */
export const orphanSeatMessage = createSelector([getSelectedEvent],(event) =>  {
    return typeof event?.reserved_seating?.orphanSeatMessage === 'string' ? event?.reserved_seating?.orphanSeatMessage : '';
});

/**
 * Get array of tickets that are seated
 */
export const getSeatedTickets = createSelector([getTickets],(tickets) =>  {
    return filterSeatedTickets(tickets);
});

/**
 * Is there a reserve coupon usage associated with the selected event
 */
export const hasAppliedReserveCoupon = createSelector([getSelectedEvent, getReserveCoupons],(event, reserveCouponUsages) =>  {
    return reserveCodeApplied(event, reserveCouponUsages);
});

/**
 * Does the selected event have reserve coupons
 */
export const hasReserveCoupon = createSelector([getSelectedEvent],(event) =>  {
    return !!event?.event?.hasReserveCoupon;
});

/**
 * Get the reserve coupon usage if one has been applied
 */
export const getReserveCouponUsage = createSelector([getSelectedEvent, getReserveCoupons],(event, reserveCouponUsages) =>  {
    return getReserveCouponUsageForEvent(event, reserveCouponUsages);
});

/**
 * Does the chart use labels instead of uuids
 */
export const usesLabels = createSelector([getSelectedEvent],(event) =>  {
    return !!event?.reserved_seating?.usesLabels;
});

const filterSeatedTickets = tickets => {
    return (Array.isArray(tickets) ? tickets : []).filter(ticket => !!ticket?.is_seated);
}

const eventIsPyos = event => {
    return !!event?.reserved_seating?.isPyos;
}

const eventUsesServerSideBestAvailable = event => {
    return !!event?.reserved_seating?.useServerSideBestAvailable;
}

const eventUsesSeatsio = event => {
    return !!event?.reserved_seating?.usesSeatsio;
}

const eventIsSeated = event => {
    return eventUsesSeatsio(event);
}

const reserveCodeApplied = (event, reserveCouponUsages) => {
    return !!getReserveCouponUsageForEvent(event, reserveCouponUsages);
}

const getReserveCouponUsageForEvent = (event, reserveCouponUsages) => {
    const usagesForEvent = reserveCouponUsages.filter(coupon =>  !!coupon?.attributes?.event_id  && +coupon.attributes.event_id === +event?.event_id);
    return usagesForEvent.length ? usagesForEvent[0] : null;
}

/**
 * Apply a section level discount to a price level to calculate the discounted price
 * @param  object priceLevelObject
 * @param  object sectionPriceLevel
 * @return number
 */
export const calculateDiscountedLevelPriceFromSectionLevel = (priceLevelObject, sectionPriceLevel) => {
    const currency = priceLevelObject?.price?.currency ?? 'USD';
    let cost = +priceLevelObject?.price?.price
    const discountValue = +sectionPriceLevel?.discount;
    if (!!discountValue) {
        if (+sectionPriceLevel?.discount_type === DISCOUNT_TYPE_FLAT) {
            cost = Math.max(0, +priceLevelObject?.price?.price - toMinorUnit(discountValue, currency));
        }
        if (+sectionPriceLevel?.discount_type === DISCOUNT_TYPE_PERCENT) {
            cost = Math.max(0, +priceLevelObject?.price?.price * ((100 - discountValue) * .01));
        }
    }
    return Math.max(0, cost);
}

/**
 * Apply section price levels to price levels to get pricing object for seating chart
 */
export const calculateSeatPricing = (priceLevels, sectionPriceLevels, sectionFeeMap = {},  includesFee = true) => {
    return Object.values(priceLevels).map(priceLevel => {
        const relatedSectionLevels = !priceLevel.is_seated ? [] : sectionPriceLevels.filter(sectionLevel => {
            return +sectionLevel?.price_level_id === 0 || +sectionLevel?.price_level_id === +priceLevel?.price_level_id;
        });
        const result = {
            category: priceLevel.name,
            priceLevelId: priceLevel?.price_level_id,
        };
        const priceLevelFee = (sectionFeeMap[priceLevel?.price?.price.toString()] != null)
        ? +sectionFeeMap[priceLevel?.price?.price.toString()]
        : +priceLevel?.service_fee?.price;

        if (!relatedSectionLevels.length) {
          result.price = +priceLevel?.price?.price + (includesFee ? +priceLevelFee : 0);
          return result;
        }

        result.ticketTypes = [
            {
                ticketType: priceLevel?.reserved_seating_label ? priceLevel?.reserved_seating_label : 'Regular',
                price: +priceLevel?.price?.price + (includesFee ? +priceLevelFee : 0),
                priceWithoutFee: +priceLevel?.price?.price,
                pplId: 0
            }
        ].concat(relatedSectionLevels.map(rsl => {
            let discountedPrice = calculateDiscountedLevelPriceFromSectionLevel(priceLevel, rsl);
            let discountedPriceLevelFee = (sectionFeeMap[discountedPrice] != null)
                ? +sectionFeeMap[discountedPrice]
                : +priceLevel?.service_fee?.price;
            return {
                ticketType: rsl?.name ?? '',
                price: discountedPrice + (includesFee ? discountedPriceLevelFee : 0),
                priceWithoutFee: discountedPrice,
                pplId: +rsl.id
            };
        }));
        return result;
    });
}
